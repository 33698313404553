<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <div class="mt-2" id="collapse-1">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="group"
                  v-model="filter.byAttribute.code"
                  type="text"
                  label="Codice Gruppo"
                  placeholder="Inserisci un gruppo"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="titolo"
                  v-model="filter.byAttribute.title"
                  type="text"
                  label="Descrizione"
                  placeholder="Inserisci un titolo"
                />
              </div>
              <div class="form-group col-md-3 align-self-end">
                <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
          </b-card>
        </b-form>
      </div>
    </b-collapse>
    <!-- <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[{
        code: 'EXPOREGR',
        label: null,
        formats: ['csv'],
      }]"
    ></export-options> -->
    <!-- <button-group-table
      @csv="onExport('csv')"
      :options="{
        CSV: { enabled: true },
      }"
    ></button-group-table> -->

    <table-group
      @edit="onEdit"
      @destroy="onDestroy"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: ['byRegistry'],
      }"
      :ref="tableRef"
      :onlyActions="['edit', 'destroy']"
    ></table-group>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import TableGroup from "@/components/tables/RegistryGroups";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
// import ExportOptions from "@/components/ExportOptions";
import BaseInput from "@/components/form/BaseInput";
import { mapActions } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
// import ExportsMixin from "@/mixins/ExportsMixin";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      // exportUrl: null,
      repository: "registry_group",
      resource: "registry_groups",
      filterName: "filterRegistryGroup",
      filter: this.initFilter(),
      items: [],
      tableRef: "RegistryGroupTableRef",
      fields: [
        {
          key: "code",
          value: "code",
          label: this.getDictionary("code", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.code",
        },
        {
          key: "title",
          value: "title",
          label: this.getDictionary("title", "registry_group"),
          sortable: true,
          sortKey: "byAttribute.title",
        },
        {
          key: "anagrafica",
          value: (item) =>
            item.registries
              ? item.registries
                  .map((e) => {
                    return e.status_registry.value === 1
                      ? e.attributables.CNAM
                      : e.attributables.NAME + " " + e.attributables.SURN;
                  })
                  .join(",")
              : "",
          label: this.getDictionary("registry"),
          sortable: false,
        },
      ],
    };
  },
  components: {
    TableGroup,
    // ButtonGroupTable,
    // ExportOptions,
    BaseInput,
  },
  created() {},
  methods: {
    initFilter() {
      let init = {
        byAttribute: {
          code: null,
          title: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "attributables") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `registry_groups.edit`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      this.$router.push({ name: `registry_groups.create` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Gruppo Eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>
